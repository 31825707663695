<template>
  <v-container class="advanced-security">
    <h1>{{ $t("advancedSecurity.mfa") }}</h1>
    <p>{{ $t("advancedSecurity.explain") }}</p>
    <v-checkbox
      v-model="mfaEnabled"
      @change="updateMfa"
      :label="$t('advancedSecurity.mfa')"
    />
  </v-container>
</template>
<style lang="scss">
.advanced-security {
  .headers {
    font-weight: 600;
  }
}
</style>
<script>
import SecurityService from "~/service/securityService";
export default {
  name: "MyAdvancedSecurity",
  data() {
    return {
      loading: true,
      config: null,
      mfaEnabled: false
    };
  },
  computed: {},
  methods: {
    async fetchMfaEnabled() {
      try {
        this.loading = true;
        SecurityService.getMfaEnabled().then(data => {
          this.loading = false;
          this.mfaEnabled = data.value;
        });
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async updateMfa(value) {
      let response = await this.$dialog.confirm({
        title: this.$t("advancedSecurity.mfa"),
        text: value
          ? this.$t("advancedSecurity.mfaEnableMessage")
          : this.$t("advancedSecurity.mfaDisableMessage"),
        persistent: true
      });
      if (response) {
        SecurityService.updateMfa(value);
      } else {
        this.mfaEnabled = !value;
      }
    }
  },
  async created() {
    await this.fetchMfaEnabled();
  }
};
</script>
